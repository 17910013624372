<template>
  <div class="login">
    <el-row>
      <el-col>
        <div class="login-form-box">
          <el-form label-width="auto" size="large" style="max-width: 600px">
            <el-form-item>
              <el-avatar shape="square" :size="200" :src="require('../assets/logo.png')" style="margin: auto; border-radius: 20%;box-shadow: 10px 10px 10px #666;" />
            </el-form-item>
            <el-form-item>
              <div style="width: 100%;">
                <h1 style="text-align: center;">服务器状态监看</h1>
              </div>
            </el-form-item>
            <el-form-item label="授权码（密码）：">
              <el-tooltip effect="light" content="为了您的账号安全，本系统不支持账号密码登录" placement="top">
                <el-input v-model="data.password" type="password" placeholder="请粘贴系统分配的授权码" />
              </el-tooltip>
            </el-form-item>
            <el-form-item>
              <el-button style="width: 100%;" type="primary" @click="login()">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { reactive } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import api from '../apis/api'
import cache from '../utils/cache'
import util from '../utils/util'
import crypto from '../utils/crypto'
const router = useRouter()
const data = reactive({
  password: ''
})

const user = cache.getObject(cache.keys.loginUser)
if (user && user.token) {
  router.replace('/')
}

const login = () => {
  if (data.password.length < 16) {
    ElMessage.warning('密码格式错误，请重试')
    return
  }
  api.get('/backend/user/getLoginKey').then(res => {
    if (!res.date) {
      ElMessage.error('登录失败，请重试')
    }
    crypto.SHA256(res.date + data.password).then(sha256Password => {
      api.post('/backend/user/login', {
        key: data.password.substring(0, 16),
        sha256: sha256Password,
        date: res.date
      }).then(res => {
        cache.setObject(cache.keys.loginUser, res)
        ElMessage.success('登录成功')
        router.replace('/')
      })
    })
  })
}
</script>

<style lang="less">
.login {
  width: 100vw;
  height: 100vh;
  background-color: #eee;

  .login-form-box {
    margin: auto;
    height: 500px;
    min-width: 300px;
    max-width: 450px;
    margin-top: calc(50vh - 300px);
    padding: 50px;
    width: 50%;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 20px;
  }
}
</style>